import React, { useEffect } from "react";

import Home from "../components/pages/Home";
import Login from "../components/pages/Login";
import Profile from "../components/pages/Profile";
import Dashboard from "../components/pages/Dashboard";

import ClientListItem from "../components/layout/ClientListItem";
import PrivateRoute from "./PrivateRoute";
import { getProfile } from "../actions/userActions";

import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

const Routes = ({ getProfile }) => {
  useEffect(() => {
    if (localStorage.token) getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/clients/:id" component={ClientListItem} />
    </Switch>
  );
};

export default connect(null, { getProfile })(Routes);
