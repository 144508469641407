import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { logout } from "../../actions/userActions";

import logo from "../../assets/ZIngsigns media logo.png";

const PublicLinks = () => {
  return (
    <>
      <li>
        <Link to="/">Screening</Link>
      </li>

      <li>
        <Link to="/login">Login</Link>
      </li>
    </>
  );
};

const PrivateLinks = ({ logout }) => {
  return (
    <>
      <li>
        <Link to="/">Screening</Link>
      </li>

      <li>
        <Link to="/dashboard">Dashboard</Link>
      </li>

      <li>
        <Link to="/profile">Profile</Link>
      </li>

      <li>
        <a href="#!" onClick={logout}>
          Logout
        </a>
      </li>
    </>
  );
};

const Header = ({ isLoggedIn, logout }) => {
  useEffect(() => {
    /** Grab Company Logo Here */
  }, []);

  return (
    <header className="header">
      <h1 className="logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </h1>

      <input type="checkbox" id="nav-toggle" className="nav-toggle" />

      <nav>
        <ul>
          {isLoggedIn ? <PrivateLinks logout={logout} /> : <PublicLinks />}
        </ul>
      </nav>

      <label htmlFor="nav-toggle" className="nav-toggle-label">
        <span></span>
      </label>
    </header>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, { logout })(Header);
