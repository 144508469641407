import { LOGIN, LOGOUT, GET_PROFILE } from "../store/Types";

const initialState = {
  profile: null,
  isLoggedIn: false,
  token: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        profile: action.payload.profile,
        isLoggedIn: true,
        token: action.payload.token,
      };

    case LOGOUT:
      localStorage.removeItem("token");

      return {
        ...initialState,
      };

    case GET_PROFILE:
      return {
        ...state,
        isLoggedIn: true,
        profile: action.payload.profile,
        token: action.payload.token,
      };

    default:
      return state;
  }
};
