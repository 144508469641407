import Toastify from "toastify-js";

export default (message, color = "green", duration = 3000) => {
  Toastify({
    text: message,
    duration,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: color,
    stopOnFocus: true,
  }).showToast();
};
