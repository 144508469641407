import React from "react";

import ScreeningForm from "../layout/ScreeningForm";

const Home = () => {
  return (
    <div className="container">
      <h1 className="text-primary text-center">COVID-19 Screening Form</h1>

      <ScreeningForm />
    </div>
  );
};

export default Home;
