import React from "react";
import PropTypes from "prop-types";

// import { BsFillXCircleFill, BsPencil, BsFillEyeFill } from "react-icons/bs";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import moment from "moment";

import numberFormatter from "../../utils/numberFormatter";

const ScreeningListTable = ({ headers, data, loading }) => {
  return (
    <div style={{ overflowX: "auto", marginBottom: "2rem" }}>
      <table className="client-list-table">
        <thead>
          <tr>
            {headers.map((header, id) => (
              <td key={id}>{header}</td>
            ))}
          </tr>
        </thead>

        <tbody>
          {loading.type === "screening" && loading.active && (
            <tr>
              <td colSpan={headers.length} className="text-center">
                Loading...
              </td>
            </tr>
          )}
          {data.length === 0 && !loading.active && (
            <tr>
              <td colSpan={headers.length} className="text-center">
                No Submissions
              </td>
            </tr>
          )}
          {!loading.active &&
            data.map(
              ({ _id, name, numbers, email, checkInDate, responseList }) => (
                <tr key={_id}>
                  {/* <td></td> */}
                  <td>{name}</td>
                  <td>
                    {numbers.map((number, id) => (
                      <div key={id}>
                        <a href={`tel:${number}`}>{numberFormatter(number)}</a>
                      </div>
                    ))}
                  </td>
                  <td>
                    <a href={`mailto:${email}`}>{email}</a>
                  </td>
                  <td>{moment(checkInDate).format("ddd MMMM Do, YYYY")}</td>

                  {/* <td>
                <Link to={`/clients/${_id}`}>
                  <BsFillEyeFill />
                </Link>
                <BsFillXCircleFill /> <BsPencil />
              </td> */}
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

ScreeningListTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.general.loading,
});

export default connect(mapStateToProps, {})(ScreeningListTable);
