import {
  GET_SCREENINGS,
  ADD_SCREENING,
  EDIT_SCREENING,
  DELETE_SCREENING,
  DELETE_SCREENINGS,
  SET_CURRENT_SCREENING,
  CLEAR_CURRENT_SCREENING,
  SELECT_SCREENING,
  UNSELECT_SCREENING,
  SELECT_ALL_SCREENINGS,
  UNSELECT_ALL_SCREENINGS,
  SORT_SCREENINGS,
  FILTER_SCREENINGS,
} from "../store/Types";

const initialState = {
  screenings: [],
  sortedScreenings: [],
  filteredScreenings: [],
  selectedScreenings: [],
  currentScreening: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SCREENINGS:
      return {
        ...state,
        screenings: action.payload,
      };

    case ADD_SCREENING:
      return {
        ...state,
        screenings: [...state.screenings, action.payload],
      };

    case EDIT_SCREENING:
      return {
        ...state,
        screenings: state.screenings.map((screening) =>
          screening._id === action.payload._id ? action.payload._id : screening
        ),
      };

    case DELETE_SCREENING:
      return {
        ...state,
        screenings: state.screenings.filter(
          (screening) => screening._id !== action.payload._id
        ),
      };
    case DELETE_SCREENINGS:
      return {
        ...state,
        screenings: state.screenings.filter(
          (screening) =>
            screening._id ===
            action.payload.find(
              (screeningItem) => screening._id === screeningItem._id
            )
        ),
      };

    case SET_CURRENT_SCREENING:
      return {
        ...state,
        currentScreening: action.payload,
      };

    case CLEAR_CURRENT_SCREENING:
      return {
        ...state,
        currentScreening: null,
      };

    case SELECT_SCREENING:
      return {
        ...state,
        selectedScreenings: [...state.selectedScreenings, action.payload],
      };

    case UNSELECT_SCREENING:
      return {
        ...state,
        selectedScreenings: state.selectedScreening.map((screening) =>
          screening._id === action.payload ? action.payload : screening
        ),
      };

    case SELECT_ALL_SCREENINGS:
      return {
        ...state,
        selectedScreenings: state.screenings.map((screening) => screening._id),
      };

    case UNSELECT_ALL_SCREENINGS:
      return {
        ...state,
        selectedScreening: [],
      };

    case SORT_SCREENINGS:
      return {
        ...state,
        sortedScreenings: state.screenings.sort(
          (screening1, screening2) =>
            screening1[action.payload] - screening2[action.payload]
        ),
      };

    case FILTER_SCREENINGS:
      return {
        ...state,
        filteredScreenings: state.screenings.filter((screening) => {
          for (const key of screening) {
            if (!screening[key].toString().includes(action.payload))
              return true;
          }
          return false;
        }),
      };
    default:
      return state;
  }
};
