export default (number, shouldFormat = true) => {
  if (!number) return;

  if (shouldFormat) {
    const numString = number.toString().replace(/\D/g, ""); //Cast to string and clean up number
    const numArray = numString.split("");

    return numArray.length === 11
      ? `${numArray[0]} (${numArray[1]}${numArray[2]}${numArray[3]}) - ${numArray[4]}${numArray[5]}${numArray[6]} - ${numArray[7]}${numArray[8]}${numArray[9]}${numArray[10]}`
      : `(${numArray[0]}${numArray[1]}${numArray[2]}) ${numArray[3]}${numArray[4]}${numArray[5]} - ${numArray[6]}${numArray[7]}${numArray[8]}${numArray[9]}`;
  }

  return number.replace(/\D/g, "");
};
