import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { addScreening } from "../../actions/screeningActions";

// import SignatureCanvas from "react-signature-canvas";
// import DatePicker from "react-datepicker";

// import toastify from "../../utils/toastify";

const ScreeningForm = () => {
  const { loading } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [form, setForm] = useState({
    fullName: "",
    primaryNumber: "",
    secondaryNumber: "",
    thirdNumber: "",
    email: "",
    question1: {
      description: "Do you have a new onset of any of the following symptoms",
      symptomList: ["Fever", "Cough", "Sore throat", "Shortness of breath"],
      response: null,
    },
    question2: {
      description:
        "Have you been in contact in the last 14 days with someone that is confirmed to have COVID-19?",
      response: null,
    },
    question3: {
      description: "Have you travelled outside of Ontario in the last 14 days?",
      response: null,
    },
    agreement: null,
    // signature: "",
    // date: new Date(),
  });

  // let signature;

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (signature.isEmpty()) {
    //   toastify("Please provide your signature", "red");
    //   setStatus("idle");
    //   return;
    // }

    // setForm({
    //   ...form,
    //   signature: signature.getTrimmedCanvas().toDataURL("image/png"),
    // });

    dispatch(
      addScreening({
        ...form,
        responseList: [form.question1, form.question2, form.question3],
      })
    )
      .then(() => setFormSubmitted(true))
      .catch(() => setFormSubmitted(false));
  };

  // const handleQuestionChange = (e) => {};

  const handleChange = (e) => {
    const id = e.target.id;
    const name = e.target.name;
    const checked = e.target.checked;
    const value = e.target.value;

    if (id === "agreementYes") {
      setForm({ ...form, [id]: checked });
      return;
    }

    if (checked) {
      id.includes("Yes")
        ? setForm((prevState) => ({
            ...form,
            [name]: { ...prevState[name], response: true },
          }))
        : setForm((prevState) => ({
            ...form,
            [name]: { ...prevState[name], response: false },
          }));
    } else {
      setForm({ ...form, [id]: value });
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "1250px" }}>
      {/* DISBALE FORM AND SHOW CRITICAL MESSAGE IF CLIENT IS SHOWING SYMPTOMS */}
      {(form.question1.response ||
        form.question2.response ||
        form.question3.response) && (
        <div className="sticky background-primary text-light">
          If you have answered yes to any of these questions, please do not
          enter the establishment or if you have already entered, exit
          immediately. Stay home, and monitor your health. If symptoms worsen,
          contact Hamilton Public Health:{" "}
          <a href="tel:905-974-9848">905-974-9848</a>
        </div>
      )}

      <div className="flex">
        <div className="form-item">
          <label htmlFor="name">Full Name</label>

          <input
            type="text"
            placeholder="Your full name"
            id="fullName"
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-item">
          <label htmlFor="number">Phone Number</label>

          <input
            type="text"
            placeholder="Your Primary Phone Number"
            id="primaryNumber"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            placeholder="Your Secondary Phone Number"
            id="secondaryNumber"
            onChange={handleChange}
          />
          <small>Secondary Number Optional</small>
        </div>

        <div className="form-item">
          <label htmlFor="number">Email</label>

          <input
            type="email"
            placeholder="Your email address"
            id="email"
            onChange={handleChange}
            required
          />
          <small>Optional</small>
        </div>
      </div>

      <div className="flex">
        <div className="form-item">
          <label htmlFor="">
            1. Do you have a new onset of any of the following symptoms:
            <ul>
              <li>Fever</li>
              <li>Cough</li>
              <li>Sore throat</li>
              <li>Shortness of breath</li>
            </ul>
          </label>

          <ul>
            <li>
              <div className="radio">
                <input
                  type="radio"
                  name="question1"
                  id="questionYes1"
                  onChange={handleChange}
                  required
                />
                <span className="radio-label">Yes</span>
              </div>
            </li>
            <li>
              <div className="radio">
                <input
                  type="radio"
                  name="question1"
                  id="questionNo1"
                  onChange={handleChange}
                />
                <span className="radio-label">No</span>
              </div>
            </li>
          </ul>
        </div>

        <div className="form-item">
          <label htmlFor="">
            2. Have you been in contact in the last 14 days with someone that is
            confirmed to have COVID-19?
          </label>

          <ul>
            <li>
              <div className="radio">
                <input
                  type="radio"
                  name="question2"
                  id="questionYes2"
                  onChange={handleChange}
                  required
                />
                <span className="radio-label">Yes</span>
              </div>
            </li>
            <li>
              <div className="radio">
                <input
                  type="radio"
                  name="question2"
                  id="questionNo2"
                  onChange={handleChange}
                />
                <span className="radio-label">No</span>
              </div>
            </li>
          </ul>
        </div>

        <div className="form-item">
          <label htmlFor="">
            3. Have you travelled outside of Ontario in the last 14 days?
          </label>

          <ul>
            <li>
              <div className="radio">
                <input
                  type="radio"
                  name="question3"
                  id="questionYes3"
                  onChange={handleChange}
                  required
                />
                <span className="radio-label">Yes</span>
              </div>
            </li>
            <li>
              <div className="radio">
                <input
                  type="radio"
                  name="question3"
                  id="questionNo3"
                  onChange={handleChange}
                />
                <span className="radio-label">No</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="form-item full-width">
        <label htmlFor="">
          The novel coronavirus, COVID-19, has been declared a worldwide
          pandemic by the World Health Organization and COVID-19 is extremely
          contagious. We have put in place preventative measures to reduce the
          spread of COVID-19; however, we cannot guarantee that the Client will
          not become infected with COVID-19. Further, entering our store could
          increase your risk of contracting COVID-19.
        </label>

        <ul>
          <li>
            <div className="radio">
              <input
                type="checkbox"
                name="agreement"
                id="agreementYes"
                onChange={handleChange}
                required
              />
              <span className="radio-label">
                I have read and understand the rules and regulations.
              </span>
            </div>
          </li>
        </ul>
      </div>

      {/* <div className="form-item full-width">
        <label htmlFor="signature">Electronic Signature:</label>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ height: 350, className: "sigCanvas" }}
          ref={(ref) => {
            signature = ref;
          }}
        />

        <button
          type="button"
          onClick={() => signature.clear()}
          className="button-primary"
        >
          Clear Signature
        </button>
      </div> */}

      <div className="form-item">
        <input
          type="submit"
          value={loading.active ? "Loading..." : "Submit"}
          disabled={
            formSubmitted ||
            form.question1.response ||
            form.question2.response ||
            form.question3.response
          }
        />
      </div>
    </form>
  );
};

export default ScreeningForm;
