import { LOGIN, LOGOUT, GET_PROFILE, SET_LOADING } from "../store/Types";

import axios from "axios";

import toastify from "../utils/toastify";
import handleError from "../utils/handleError";

const payload = "user";

export const login = (email, password) => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_LINK}/authenticate/login`,
      { email, password }
    );

    dispatch({ type: LOGIN, payload: res.data });

    //Keep the token in memory and set the default authentication header to
    //have the token for requests to the API
    localStorage.setItem("token", res.data.token);
    axios.defaults.headers.common["x-authentication-token"] =
      localStorage.token;

    toastify("Logged In Successfully!", "green");

    dispatch({ type: SET_LOADING });
  } catch (error) {
    handleError(error);
  }
};

export const logout = () => async (dispatch) => dispatch({ type: LOGOUT });

export const getProfile = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload });

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_LINK}/authenticate`
    );

    dispatch({ type: GET_PROFILE, payload: res.data });

    dispatch({ type: SET_LOADING });
  } catch (error) {
    handleError(error);
  }
};
