import { GET_SCREENINGS, ADD_SCREENING, SET_LOADING } from "../store/Types";

import axios from "axios";

import toastify from "../utils/toastify";
import handleError from "../utils/handleError";

const payload = "screening";

export const getScreenings = (from, to) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_LINK}/screenings`,
      {
        params: { from, to },
      }
    );

    dispatch({
      type: GET_SCREENINGS,
      payload: data.screenings,
    });
    dispatch({ type: SET_LOADING });
  } catch (error) {
    handleError(error);
  }
};

export const addScreening = (screening) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload });

    await axios.post(`${process.env.REACT_APP_API_LINK}/screenings`, screening);

    dispatch({ type: ADD_SCREENING, payload: screening });

    toastify("Thanks for Your Submission!");

    dispatch({ type: SET_LOADING });
  } catch (error) {
    handleError(
      error,
      "There was a problem getting submitting your screening, please advise our team about this immediately"
    );
  }
};
