import Store from "../store/Store";
import toastify from "./toastify";
import { SET_LOADING, LOGOUT } from "../store/Types";

const color = "red";
const time = 4000;
const defaultMessage =
  "There seems to be a problem right now, please try again later";

export default (error, message = defaultMessage) => {
  Store.dispatch({ type: SET_LOADING }); //Stop loading state

  if (error.response) {
    if (error.response.status === 403) {
      Store.dispatch({ type: LOGOUT });
      return toastify(error.response.data.message, color, time);
    }

    toastify(error.response.data.message, color, time);
  } else {
    toastify(message, color, time);
  }
};
