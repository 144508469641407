import React, { useState } from "react";

import { connect } from "react-redux";

import { login } from "../../actions/userActions";

const LoginForm = ({ login, isLoggedIn, loading }) => {
  const [form, setLogin] = useState({ email: "", password: "" });

  const handleSubmit = (e) => {
    e.preventDefault();

    login(form.email, form.password);
  };

  const handleChange = (e) =>
    setLogin({ ...form, [e.target.id]: e.target.value });

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex">
        <div className="form-item half-width">
          <label htmlFor="name">Email</label>

          <input
            type="text"
            placeholder="Your email"
            id="email"
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-item half-width">
          <label htmlFor="name">Password</label>

          <input
            type="password"
            placeholder="Your password"
            id="password"
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="form-item">
        <input
          type="submit"
          value={loading.active ? "Loading..." : "Login"}
          disabled={loading.active ? true : false}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  loading: state.general.loading,
});

export default connect(mapStateToProps, { login })(LoginForm);
