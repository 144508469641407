import {
  GET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  DELETE_CLIENTS,
  SET_CURRENT_CLIENT,
  CLEAR_CURRENT_CLIENT,
  SELECT_CLIENT,
  UNSELECT_CLIENT,
  SELECT_ALL_CLIENTS,
  UNSELECT_ALL_CLIENTS,
  SORT_CLIENTS,
  FILTER_CLIENTS,
} from "../store/Types";

const initialState = {
  clients: [],
  sortedClients: [],
  filteredClients: [],
  selectedClients: [],
  currentClient: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };

    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload.client],
      };

    case EDIT_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) =>
          client._id === action.payload.id ? action.payload : client
        ),
      };

    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter(
          (client) => client._id !== action.payload.id
        ),
      };

    case DELETE_CLIENTS:
      return {
        ...state,
        clients: state.clients.filter(
          (client) =>
            client ===
            action.payload.find((clientItem) => clientItem._id === client._id)
        ),
      };

    case SET_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };
    case CLEAR_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: null,
      };

    case SELECT_CLIENT:
      return {
        ...state,
        selectedClients: [...state.selectedClients, action.payload],
      };

    case UNSELECT_CLIENT:
      return {
        ...state,
        selectedClients: state.selectedClients.filter(
          (selectedClient) => selectedClient._id !== action.payload._id
        ),
      };

    case SELECT_ALL_CLIENTS:
      return {
        ...state,
        selectedClients: [...state.clients],
      };

    case UNSELECT_ALL_CLIENTS:
      return {
        ...state,
        selectedClients: [],
      };

    case SORT_CLIENTS:
      return {
        ...state,
        clients: state.clients.sort(
          (client1, client2) =>
            client1[action.payload] - client2[action.payload]
        ),
      };

    case FILTER_CLIENTS:
      return {
        ...state,
        filteredClients: state.clients.filter((client) => {
          for (const key of client) {
            if (!client[key].toString().includes(action.payload)) {
              return true;
            }
          }
          return false;
        }),
      };

    default:
      return state;
  }
};
