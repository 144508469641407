import { combineReducers } from "redux";

import GeneralReducer from "./GeneralReducer";
import UserReducer from "./UserReducer";
import ScreeningReducer from "./ScreeningReducer";
import ClientsReducer from "./ClientsReducer";

export default combineReducers({
  general: GeneralReducer,
  user: UserReducer,
  screening: ScreeningReducer,
  clients: ClientsReducer,
});
