import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import ScreningListTable from "../layout/ScreeningListTable";
import ScreeningDateRanges from "../layout/ScreeningDateRanges";

import { getScreenings } from "../../actions/screeningActions";
import { getClients } from "../../actions/clientActions";

import moment from "moment";

const Dashboard = ({ getScreenings, screenings, loading }) => {
  const list = screenings.map(
    ({
      _id,
      fullName,
      primaryNumber,
      secondaryNumber,
      thirdNumber,
      email,
      dateVisited,
      responseList,
    }) => {
      return {
        _id,
        name: fullName,
        numbers: [primaryNumber, secondaryNumber, thirdNumber],
        email,
        checkInDate: dateVisited,
        responseList,
      };
    }
  );

  const [dateRanges, setDateRanges] = useState({
    from: new Date(),
    to: new Date(),
  });

  const dateRangesCB = (from = new Date(), to = new Date()) => {
    if (from !== null && to !== null) setDateRanges({ from, to });
  };

  useEffect(() => {
    getScreenings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = ["Name", "Phone Numbers", "Email", "Check-in Date"];

  return (
    <>
      <div className="container-fluid">
        <h1 className="text-primary text-center" style={{ marginTop: "3rem" }}>
          Dashboard
        </h1>
        <h2 className="text-primary text-center" style={{ margin: "1rem 0" }}>
          Screening Submissions
        </h2>

        <h3 className="text-secondary text-center">
          {dateRanges.from.toLocaleDateString() ===
          dateRanges.to.toLocaleDateString()
            ? moment(dateRanges.from).format("ddd MMMM Do, YYYY")
            : `Between ${moment(dateRanges.from).format(
                "ddd MMMM Do, YYYY"
              )} and ${moment(dateRanges.to).format("ddd MMMM Do, YYYY")}`}
        </h3>

        <div style={{ maxWidth: "750px", margin: "auto" }}>
          <ScreeningDateRanges setRanges={dateRangesCB} />
        </div>

        <ScreningListTable headers={headers} data={list} />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  screenings: state.screening.screenings,
  loading: state.general.loading,
});

export default connect(mapStateToProps, { getScreenings, getClients })(
  Dashboard
);
