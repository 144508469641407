import React from "react";

import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";

const PrivateRoute = ({ path, component, isLoggedIn }) => {
  return isLoggedIn ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(PrivateRoute);
