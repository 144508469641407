import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getClient, clearCurrentClient } from "../../actions/clientActions";

const ClientListItem = ({ match, getClient, currentClient, loading }) => {
  useEffect(() => {
    if (match.params.id) getClient(match.params.id);

    return () => {
      clearCurrentClient();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    currentClient &&
    !loading.active && (
      <div className="container">
        <h1 className="text-primary text-center">Client Details</h1>

        {currentClient === null && <h3>Client not found...</h3>}

        {currentClient && (
          <>
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{`${currentClient.firstName} ${currentClient.lastName}`}</td>
                </tr>

                <tr>
                  <td>Primary Number</td>
                  <td>{currentClient.primaryNumber}</td>
                </tr>

                <tr>
                  <td>Secondary Number</td>
                  <td>{currentClient.secondaryNumber}</td>
                </tr>

                <tr>
                  <td>Third Number</td>
                  <td>{currentClient.thirdNumber}</td>
                </tr>

                <tr>
                  <td>Submissions</td>
                  <td>
                    {
                      <table>
                        <thead>
                          <th>
                            <td>Date Visited</td>
                          </th>

                          <th></th>
                        </thead>
                        <tbody>
                          {currentClient.screeningSubmissions.map(
                            (submission) => (
                              <tr>
                                <td>{submission.dateVisited}</td>
                                <td>
                                  <a href="#!">X</a>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    }
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <p>Name: {currentClient.name}</p>
          <p>Primary Number: {currentClient.numbers[0]}</p>
          <p>Secondary Number: {currentClient.numbers[1]}</p>
          <p>Third Number: {currentClient.numbers[2]}</p>
          <p>Email: {currentClient.email}</p>
          <p>
            Check-in Date:{" "}
            {moment(currentClient.checkInDate).format("dddd MMMM Do, YYYY")}
          </p> */}
          </>
        )}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  currentClient: state.clients.currentClient,
  loading: state.general.loading,
});

export default connect(mapStateToProps, { getClient, clearCurrentClient })(
  ClientListItem
);
