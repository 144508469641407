import React from "react";

import { BsFillEnvelopeFill, BsPhone } from "react-icons/bs";

import { connect } from "react-redux";

import profilePicture from "../../assets/profile-picture-generic.png";

const Profile = ({ profile, loading }) => {
  return (
    <div className="container">
      <h1 className="text-primary text-center">Your Profile</h1>

      <div className="flex">
        <div>
          <img
            src={profilePicture}
            alt=""
            style={{ width: "100%", maxWidth: "15rem" }}
          />
        </div>

        <div>
          <h2>{`${profile.firstName} ${profile.lastName}`}</h2>
          <h3>{profile.title}</h3>
          <p>
            <BsFillEnvelopeFill style={{ margin: "0" }} />{" "}
            <span style={{ verticalAlign: "text-bottom" }}>
              {profile.email}
            </span>
          </p>
          <p>
            <BsPhone style={{ margin: "0" }} /> <span> {profile.number}</span>
          </p>
        </div>
      </div>

      {/* <div className="text-center">
        <button className="button-primary">Edit Profile</button>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
  loading: state.user.loading,
});

export default connect(mapStateToProps, {})(Profile);
