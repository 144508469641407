import { SET_LOADING, CLEAR_ERRORS, HANDLE_ERRORS } from "../store/Types";

const initialState = {
  loading: { type: "default", active: false },
  errors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: {
          type: action.payload ? action.payload : state.loading.type,
          active: !state.loading.active,
        },
      };

    case HANDLE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
};
