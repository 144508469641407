import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";

import { getScreenings } from "../../actions/screeningActions";

const ScreeningDateRanges = ({ getScreenings, setRanges }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const searchDates = () => {
    setRanges(startDate, endDate);
    return startDate && endDate ? getScreenings(startDate, endDate) : null;
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    getScreenings();
    setRanges();
  };

  return (
    <>
      <div className="flex">
        <div className="control-item">
          <label htmlFor="start">Start Date</label>

          <DatePicker selected={startDate} onChange={setStartDate} />
        </div>

        <div className="control-item">
          <label htmlFor="end">End Date</label>

          <DatePicker selected={endDate} onChange={setEndDate} />
        </div>
      </div>

      <div className="control-item text-center">
        <button className="button-primary" onClick={searchDates}>
          Search Dates
        </button>
        <button className="button-secondary" onClick={clearDates}>
          Clear Dates
        </button>
      </div>
    </>
  );
};

export default connect(null, { getScreenings })(ScreeningDateRanges);
