import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import Pages from "./utils/Routes";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import "./sass/App.scss";

import Store from "./store/Store";
import { Provider } from "react-redux";

import tokenHandler from "./utils/tokenHandler";

const App = () => {
  tokenHandler();
  return (
    <Provider store={Store}>
      <Router>
        <Header />

        <Pages />

        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
