import React from "react";

import LoginForm from "../layout/LoginForm";

import { connect } from "react-redux";

import { Redirect } from "react-router-dom";

const Login = ({ isLoggedIn }) => {
  return isLoggedIn ? (
    <Redirect to="/profile" />
  ) : (
    <div className="container">
      <h1 className="text-primary text-center">COVID-19 Screening Form</h1>
      <LoginForm />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps, {})(Login);
