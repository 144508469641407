import {
  GET_CLIENTS,
  SET_CURRENT_CLIENT,
  CLEAR_CURRENT_CLIENT,
  SET_LOADING,
} from "../store/Types";

import axios from "axios";

import handleError from "../utils/handleError";

const payload = "client";

export const getClients = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload });

    const res = await axios.get(`${process.env.REACT_APP_API_LINK}/clients`);

    dispatch({ type: GET_CLIENTS, payload: res.data.clients });
    dispatch({ type: SET_LOADING });
  } catch (error) {
    handleError(error);
  }
};

export const getClient = (screeningID) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload });

    dispatch({ type: SET_CURRENT_CLIENT, payload: screeningID });
    dispatch({ type: SET_LOADING });
  } catch (error) {
    handleError(error);
  }
};

export const clearCurrentClient = () => (dispatch) =>
  dispatch({ type: CLEAR_CURRENT_CLIENT });
