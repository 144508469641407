//General Types
export const SET_LOADING = "SET_LOADING";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const HANDLE_ERRORS = "HANDLE_ERRORS";

//User Login Types
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";

//Clients Types
export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENTS = "DELETE_CLIENTS";
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const CLEAR_CURRENT_CLIENT = "CLEAR_CURRENT_CLIENT";
export const SELECT_CLIENT = "SELECT_CLIENT";
export const UNSELECT_CLIENT = "UNSELECT_CLIENT";
export const SELECT_ALL_CLIENTS = "SELECT_ALL_CLIENTS";
export const UNSELECT_ALL_CLIENTS = "UNSELECT_ALL_CLIENTS";
export const SORT_CLIENTS = "SORT_CLIENTS";
export const FILTER_CLIENTS = "FILTER_CLIENTS";

//Screening Types
export const GET_SCREENINGS = "GET_SCREENINGS";
export const ADD_SCREENING = "ADD_SCREENING";
export const EDIT_SCREENING = "EDIT_SCREENING";
export const DELETE_SCREENING = "DELETE_SCREENING";
export const DELETE_SCREENINGS = "DELETE_SCREENINGS";
export const SET_CURRENT_SCREENING = "SET_CURRENT_SCREENING";
export const CLEAR_CURRENT_SCREENING = "CLEAR_CURRENT_SCREENING";
export const SELECT_SCREENING = "CLEAR_CURRENT_SCREENING";
export const UNSELECT_SCREENING = "UNSELECT_SCREENING";
export const SELECT_ALL_SCREENINGS = "SELECT_ALL_SCREENINGS";
export const UNSELECT_ALL_SCREENINGS = "UNSELECT_ALL_SCREENINGS";
export const SORT_SCREENINGS = "SORT_SCREENINGS";
export const FILTER_SCREENINGS = "FILTER_SCREENINGS";
